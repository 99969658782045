import React, { useState } from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import PresMedSection from '../../components/PresMedSection'
import { SocialMediaLinks } from '../../utils'

import pillsIconAnimationData from '../../assets/PillsIcon.json'
import element1AnimationData from '../../assets/element1.json'
import element2AnimationData from '../../assets/element2.json'
import injectablesAnimationData from '../../assets/InjectionIcon.json'
import element3AnimationData from '../../assets/element3.json'
import element4AnimationData from '../../assets/element4.json'
import iudsAnimationData from '../../assets/IUDIcon.json'


const BlurBackground = styled.div`
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.75);
    filter: blur(3px);
    backdrop-filter: blur(7px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    z-index: 0;
`


const PrescriptionMeds = ({ pageContext, location }) => {
    const page = pageContext.page
    const [agreementOpen, setAgreementOpen] = useState(true)
    const data = useStaticQuery(graphql`
    fragment PresMeds on WpPrescriptionMedication {
        databaseId
        slug
        title
        presmedsFields {
            brandName
            fieldGroupName
            format
            formulation
            indication
            note
            popupImage {
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 560)
                    }
                }
            }
            thumbnail {
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 300)
                    }
                }
            }
        }
    }
    query {
        pills: allWpPrescriptionMedication(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "presmeds-pills"}}}}}
        ) {
            nodes {
                ...PresMeds
            }
        }

        injectables: allWpPrescriptionMedication(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "presmeds-injectables"}}}}}
        ) {
            nodes {
                ...PresMeds
            }
        }

        iuds: allWpPrescriptionMedication(
            filter: {categories: {nodes: {elemMatch: {slug: {eq: "presmeds-iuds"}}}}}
        ) {
            nodes {
                ...PresMeds
            }
        }

        favicon: file(relativePath: {eq: "logo-w431.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 431
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
    }`)

    const styles1Left = {
        sm: { width: '200px' },
        md: { width: '320px' },
        lg: { width: '620px' }
    }
    const styles1Right = {
        sm: { width: '100px' },
        md: { width: '220px' },
        lg: { width: '360px' }
    }
    const styles2Left = {
        sm: { width: '100px' },
        md: { width: '200px' },
        lg: { width: '200px' }
    }
    const styles2Right = {
        sm: { width: '100px' },
        md: { width: '200px' },
        lg: { width: '200px' }
    }

    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>

            <div className="pt-10 text-center md:pt-20 lg:pt-32 bg-pattensblue">
                <h1 className="inline-block py-4 mx-auto text-lg tracking-wider text-white uppercase rounded-full md:text-4xl bg-seance px-7">
                    Prescription Medications
                </h1>
                <div className="relative py-10 text-sm tracking-wider md:text-lg my-container">
                    <strong>Important:</strong>
                    <p className="font-light text-center text-gray-500">
                        The contents of this page are for information purposes only. They are not intended to diagnose and/or treat any medical condition and should not be interpreted as substitutes for professional medical advice. Please consult your doctor or healthcare provider before taking any prescription medicines found on this page.
                    </p>
                </div>
            </div>

            <section className="pb-10 bg-pattensblue">
                <PresMedSection 
                    title="Pills" 
                    items={ data.pills.nodes } 
                    animationData={{ 
                        logo: pillsIconAnimationData,
                        left:  { data: element1AnimationData, style:styles1Left },
                        right: { data: element2AnimationData, style:styles1Right } 
                    }}
                />
            </section>
            
            <section className="py-32 bg-pattensblue">
                <PresMedSection 
                    title="Injectables" 
                    items={ data.injectables.nodes } 
                    animationData={{ 
                        logo: injectablesAnimationData,
                        left:  { data: element3AnimationData, style:styles2Left },
                        right: { data: element4AnimationData, style:styles2Right } 
                    }}
                />
            </section>

            <section className="pt-20 pb-20 bg-pattensblue">
                <PresMedSection 
                    title="IUDs" 
                    items={ data.iuds.nodes } 
                    animationData={{ 
                        logo: iudsAnimationData,
                        left:  null,
                        right: null 
                    }}
                />
            </section>

            {agreementOpen ? (
            <div className="fixed inset-0 flex items-center justify-center " style={{ zIndex:9999999999 }}>
                <BlurBackground/>
                <div className="relative z-10 p-5 tracking-wider bg-white shadow-2xl my-container" style={{ maxWidth:'800px' }}>
                    <div className="mx-5 text-gray-500 ">
                        <p className="pb-2 text-justify">You are entering a prescription medicines page. The contents of this page are for information purposes only. They are not intended to diagnose and/or treat any medical condition and should not be interpreted as substitutes for professional medical advice. Please consult your doctor or healthcare provider before taking any prescription medicines found on this page.</p>
                        <p className="text-justify">
                            <strong className="text-red-600">Important:</strong> The Foods, Drugs, Devices, and Cosmetics Act prohibits dispensing without prescription.
                        </p>
                    </div>
                    <div className="mt-5 text-center">
                        <button className="px-5 py-2 mx-auto text-white bg-green-400 hover:bg-green-500"
                            onClick={(e) => setAgreementOpen(false)}>I Agree</button>
                    </div>
                </div>
            </div>
            ):null}
        </Layout>
    )
}

export default PrescriptionMeds