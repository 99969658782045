import React, { useRef, useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { InView } from 'react-intersection-observer'
import lottie from 'lottie-web'
import AnimatedThumbnail from './AnimatedThumbnail'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'
const twConfig = resolveConfig(tailwindConfig)

const ImageButton = styled.button`
    position: relative;
    cursor: pointer;
    width: 160px;
    min-width: 200px;
    max-width: 300px;
    padding: 0.3rem;
    @media (min-width: ${twConfig.theme.screens.sm}) {
        width: 240px;
        padding: 1rem;
    }
    @media (min-width: ${twConfig.theme.screens.md}) {
        width: 300px;
    }
`

const ElementDiv = styled.div`
    width: ${props => props.breakpointStyles?.sm.width};
    @media (min-width: ${twConfig.theme.screens.md}) {
        width: ${props => props.breakpointStyles?.md.width};
    }
    @media (min-width: ${twConfig.theme.screens.lg}) {
        width: ${props => props.breakpointStyles?.lg.width};
    }
`

const PresMedSection = ({ animationData, title, items }) => {

    const refElement1 = useRef()
    const refElement2 = useRef()
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(0)
    
    let lottieElement1 = useRef()
    let lottieElement2 = useRef()
    let playing1 = useRef()
    let playing2 = useRef()

    useEffect(() => {
        if (!!animationData.right) {
            lottieElement2.current = lottie.loadAnimation({
                container: refElement2.current,
                animationData: animationData.right.data,
                loop: false,
                autoplay: false
            })
            lottieElement2.current.addEventListener('complete', () => playing2.current = false)
        }
        if (!!animationData.left) {
            lottieElement1.current = lottie.loadAnimation({
                container: refElement1.current,
                animationData: animationData.left.data,
                loop: false,
                autoplay: false
            })
            lottieElement1.current.addEventListener('complete', () => playing1.current = false)
        }
        if (typeof window !== 'undefined') {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') setVisible(false)
            })
        }
    }, [])
    
    return (
        <>
        <div className="relative" id={`${title}-section`} style={{zIndex:20}}>
            <InView as="div" onChange={ (inView) => {
                if (!!inView) {
                    if (!!lottieElement1.current && !playing1.current) {
                        lottieElement1.current.goToAndPlay(1)
                        playing1.current = true
                    }
                    if (!!lottieElement2.current && !playing2.current) {
                        lottieElement2.current.goToAndPlay(1)
                        playing2.current = true
                    }
                }
            }}
            className="relative top-0 left-0 w-full h-full"
            >
                <ElementDiv ref={ refElement1 } className="absolute top-0 left-0"  breakpointStyles={animationData.left?.style}/>
                <ElementDiv ref={ refElement2 } className="absolute top-0 right-0" breakpointStyles={animationData.right?.style}/>
            
                <h2 className="m-auto text-3xl tracking-wider text-center text-purpleheart lg:text-4xl">
                    { title }
                </h2>

                <div className="text-center">
                    <AnimatedThumbnail animationData={ animationData.logo } hrefUrl="#" labelPosition="top" />
                </div>

                <div className="flex flex-wrap items-center justify-center my-container">
                    {items && items.map(itm => (
                        <ImageButton key={ itm.databaseId } onClick={(e) => {
                            e.preventDefault()
                            setSelected(itm.databaseId)
                            setVisible(true)
                        }} role="button">
                            <GatsbyImage className="hover:shadow-xl" image={ getImage(itm.presmedsFields.thumbnail.localFile) } alt={ itm.presmedsFields.thumbnail.altText } />
                        </ImageButton>
                    ))}
                </div>

            </InView>
        </div>
        {visible ? items.map((itm) => {
            if (itm.databaseId !== selected) return null;
            return (
                <div key={ itm.databaseId } 
                    className="fixed inset-0 flex overflow-x-hidden overflow-y-auto bg-black bg-opacity-60" 
                    style={{ zIndex:999999999999999 }}
                    onClick={(e) => {
                        e.preventDefault()
                        if (e.currentTarget === e.target) setVisible(false)
                    }} 
                    onKeyDown={(e) => {
                        if (e.key === "Escape") setVisible(false)
                    }} 
                    role="button" 
                    tabIndex={0} >
                    <div className="relative p-2 m-auto bg-white md:p-5" style={{ width:'90%', maxWidth:'1042px' }}>
                        <h4 className="text-lg font-bold tracking-wider text-center md:pb-5 md:text-2xl text-purpleheart">{ itm.title }</h4>

                        <div className="flex flex-col items-stretch justify-center md:items-stretch md:justify-evenly md:flex-row">
                            <div className="w-full text-center md:w-1/2">
                                <GatsbyImage 
                                    image={ getImage(itm.presmedsFields.popupImage.localFile.childImageSharp) } 
                                    alt={itm.presmedsFields.thumbnail.altText} />
                            </div>
                            <div className="w-full text-sm tracking-wider md:w-1/2 lg:text-md">
                                <table className="h-full p-3 border border-collapse table-auto" style={{minHeight:'200px'}}>
                                    <tbody>
                                        <tr className="border border-b-1 border-purpleheart">
                                            <td width="90" className="p-1 align-text-top border border-r-1 border-purpleheart">Brand Name</td>
                                            <td className="p-1 align-text-top">
                                                <p>{itm.presmedsFields.brandName}</p>
                                            </td>
                                        </tr>
                                        <tr className="border border-b-1 border-purpleheart">
                                            <td className="p-1 align-text-top border border-r-1 border-purpleheart">Format</td>
                                            <td className="p-1 text-justify align-text-top">
                                                <p>{itm.presmedsFields.format}</p>
                                            </td>
                                        </tr>
                                        <tr className="border border-b-1 border-purpleheart">
                                            <td className="p-1 align-text-top border border-r-1 border-purpleheart">Formulation</td>
                                            <td className="p-1 text-justify align-text-top">
                                                <p>{itm.presmedsFields.formulation}</p>
                                            </td>
                                        </tr>
                                        <tr className="border border-b-1 border-purpleheart">
                                            <td className="p-1 align-text-top border border-r-1 border-purpleheart">Indication</td>
                                            <td className="p-1 align-text-top">
                                                <p>{itm.presmedsFields.indication}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        {itm.presmedsFields.note && <p className="pb-3 text-xs tracking-wider text-red-500 md:text-sm md:pb-0">{itm.presmedsFields.note}</p>}

                        <div className="md:pt-8">
                            <p className="text-xs tracking-wider text-justify text-gray-500 md:text-sm">This is not an advertisement. The contents of this page are for information purposes only. They are not intended to diagnose and/or treat any medical condition and should not be interpreted as substitutes for professional medical advice. Please consult your doctor or health care provider before taking any prescription medicines found on this page.</p>
                        </div>
                        <div className="flex pt-4">
                            <button className="px-5 py-2 mx-auto bg-gray-300 text-md" 
                                onClick={(e) => {
                                    e.preventDefault()
                                    setVisible(false)
                                }}>Close</button>
                        </div>
                    </div>
                </div>
            )
        }):null}
        </>
    )
}

export default PresMedSection
